.btn.btn-discreet-success {
  $light-success: light-discreet-color('bg-lvl1', 'success');

  color: $light-success;

  &:hover {
    color: $light-success;
  }

  &:focus,
  &.focus {
    color: $light-success;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    color: button-active-color($primary-alt);
  }
}

.btn.btn-discreet-secondary {
  $light-secondary: text-color-light-background('bg-lvl1', 'secondary');

  color: $light-secondary;

  &:hover {
    color: $light-secondary;
  }

  &:focus,
  &.focus {
    color: $light-secondary;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    color: button-active-color($primary-alt);
  }
}

.btn.btn-discreet-info {
  $light-info: light-discreet-color('bg-lvl1', 'info');

  color: $light-info;

  &:hover {
    color: $light-info;
  }

  &:focus,
  &.focus {
    color: $light-info;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    color: button-active-color($primary-alt);
  }
}

.btn.btn-discreet-danger {
  $light-danger: light-discreet-color('bg-lvl1', 'danger');

  color: $light-danger;

  &:hover {
    color: $light-danger;
  }

  &:focus,
  &.focus {
    color: $light-danger;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    color: button-active-color($primary-alt);
  }
}

.btn.btn-discreet-warning {
  $light-warning: light-discreet-color('bg-lvl1', 'warning');

  color: $light-warning;

  &:hover {
    color: $light-warning;
  }

  &:focus,
  &.focus {
    color: $light-warning;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    color: button-active-color($primary-alt);
  }
}

.btn.btn-success {
  $bg-success-accessible: button-darken-background($success, $success-percent-reduction);
  --#{$prefix}btn-bg: #{$bg-success-accessible} !important;
  --#{$prefix}btn-hover-bg: #{button-hover-background($bg-success-accessible)} !important;
  --#{$prefix}btn-active-bg: #{$bg-success-accessible} !important;
}

.btn.btn-warning {
  --#{$prefix}btn-color: #{$primary} !important;
  --#{$prefix}btn-hover-color: #{$primary} !important;
  --#{$prefix}btn-active-color: #{$primary} !important;
}