.badge-discreet-secondary {
  --#{$prefix}badge-color: #{light-discreet-color('bg-lvl1', 'secondary')} !important;
}

.badge-discreet-info {
  --#{$prefix}badge-color: #{light-discreet-color('bg-lvl1', 'info')} !important;
}

.badge-discreet-success {
  --#{$prefix}badge-color: #{light-discreet-color('bg-lvl1', 'success')} !important;
}

.badge-discreet-warning {
  --#{$prefix}badge-color: #{light-discreet-color('bg-lvl1', 'warning')} !important;
}

.badge-discreet-danger {
  --#{$prefix}badge-color: #{light-discreet-color('bg-lvl1', 'danger')} !important;
}

.badge-outline-secondary, .badge-flat-secondary {
  --#{$prefix}badge-color: #{text-color-light-background('bg-lvl1', 'secondary')} !important;
}

.badge-outline-info, .badge-flat-info {
  --#{$prefix}badge-color: #{text-color-light-background('bg-lvl1', 'info')} !important;
}

.badge-outline-success, .badge-flat-success {
  --#{$prefix}badge-color: #{text-color-light-background('bg-lvl1', 'success')} !important;
}

.badge-outline-warning, .badge-flat-warning {
  --#{$prefix}badge-color: #{text-color-light-background('bg-lvl1', 'warning')} !important;
}

.badge-outline-danger, .badge-flat-danger {
  --#{$prefix}badge-color: #{text-color-light-background('bg-lvl1', 'danger')} !important;
}

.badge-warning {
  --#{$prefix}badge-color: #{$primary} !important;
}

.badge-success {
  background-color: #{text-color-light-background('bg-lvl1', 'success')} !important;
}
