.btn-group .btn-toggle-info:not([disabled]):not(.disabled) {
  --#{$prefix}btn-active-color: #{light-discreet-color('bg-lvl1', 'info')} !important;
  &:hover {
    --#{$prefix}btn-color:  #{light-discreet-color('bg-lvl1', 'info')} !important;
  }
  &:focus:not(button),
  &:active:not(button) {
    --#{$prefix}btn-color:  #{light-discreet-color('bg-lvl1', 'info')} !important;
    --#{$prefix}btn-active-shadow: inset 0 0 0 1px #{light-discreet-color('bg-lvl1', 'info')} !important;
  }
}

.btn-group .btn-toggle-success:not([disabled]):not(.disabled) {
  --#{$prefix}btn-active-color: #{light-discreet-color('bg-lvl1', 'success')} !important;
  &:hover {
    --#{$prefix}btn-color:  #{light-discreet-color('bg-lvl1', 'success')} !important;
  }
  &:focus:not(button),
  &:active:not(button) {
    --#{$prefix}btn-color:  #{light-discreet-color('bg-lvl1', 'success')} !important;
    --#{$prefix}btn-active-shadow: inset 0 0 0 1px #{light-discreet-color('bg-lvl1', 'success')} !important;
  }
}

.btn-group .btn-toggle-warning:not([disabled]):not(.disabled) {
  --#{$prefix}btn-active-color: #{light-discreet-color('bg-lvl1', 'warning')} !important;
  &:hover {
    --#{$prefix}btn-color:  #{light-discreet-color('bg-lvl1', 'warning')} !important;
  }
  &:focus:not(button),
  &:active:not(button) {
    --#{$prefix}btn-color:  #{light-discreet-color('bg-lvl1', 'warning')} !important;
    --#{$prefix}btn-active-shadow: inset 0 0 0 1px #{light-discreet-color('bg-lvl1', 'warning')} !important;
  }
}

.btn-group .btn-toggle-danger:not([disabled]):not(.disabled) {
  --#{$prefix}btn-active-color: #{light-discreet-color('bg-lvl1', 'danger')} !important;
  &:hover {
    --#{$prefix}btn-color:  #{light-discreet-color('bg-lvl1', 'danger')} !important;
  }
  &:focus:not(button),
  &:active:not(button) {
    --#{$prefix}btn-color:  #{light-discreet-color('bg-lvl1', 'danger')} !important;
    --#{$prefix}btn-active-shadow: inset 0 0 0 1px #{light-discreet-color('bg-lvl1', 'danger')} !important;
  }
}