@import 'bootstrap/scss/card';

// SG card variables
$card-bordering-width: px-to-rem(2) !default;
$card-hover-bg-opacity: 0.08 !default;
$card-filled-bg-opacity: 0.08 !default;
$card-active-bg-opacity: 0.15 !default;
$card-img-overlay-bg: rgba(#000, map-get($opacities, '70'));

$states: (
  valid: $form-feedback-valid-color,
  invalid: $form-feedback-invalid-color,
  edited: $form-feedback-edited-color,
  warning: $form-feedback-warning-color,
);

//
// SIZING
//
// default is 1rem, set in Bootstrap variables

$card-paddings: (
  md: 1.5rem,
);

@each $breakpoint, $value in $card-paddings {
  @include media-breakpoint-up($breakpoint) {
    .card-body {
      --#{$prefix}card-spacer-x: #{$value} !important;
      --#{$prefix}card-spacer-y: #{$value} !important;
    }

    .card-header {
      --#{$prefix}card-cap-padding-x: #{$value} !important;
      --#{$prefix}card-cap-padding-y: #{$value} !important;
    }

    .card-footer {
      --#{$prefix}card-cap-padding-x: #{$value} !important;
      --#{$prefix}card-cap-padding-y: #{$value} !important;
    }
  }
}

.card-header + .card-body {
  padding-top: 0;
}

.card-body + .card-footer {
  padding-top: 0;
}

//
// STATES
//

.card-bordered {
  outline: $border-width solid $border-color;

  &.active {
    outline: 2px solid $primary;
  }
}

.card-bordering {
  @include card-state-bordering();
}

.card-raised {
  --#{$prefix}card-box-shadow: #{$box-shadow-sm};

  &.active {
    --#{$prefix}card-box-shadow: #{$box-shadow};
  }
}

.card-filled {
  --#{$prefix}card-bg: #{rgba($primary, $card-filled-bg-opacity)};

  &.active {
    --#{$prefix}card-bg: #{rgba($primary, $card-active-bg-opacity)};
  }
}

.card-filling {
  @include card-state-filling();
}

//
// Raising card
// The card will raise/elevate toward the user when hover/focus/active
//

.card-raising {
  @include card-state-raising();
}

//
// COLORED STATES
//

@each $color, $value in $theme-colors {
  .card.card-bordering-#{$color} {
    @include card-state-bordering($value);
  }

  .card.card-filling-#{$color} {
    @include card-state-filling($value);
  }
}

@each $color, $value in $theme-colors {
  .card.card-#{$color} {
    @include card-states($value);
  }
}

//
// CARD IMAGE
//

.card-img-overlay {
  --#{$prefix}card-bg: #{$card-img-overlay-bg};
}

//
// NEWSSTAND
//
.newsstand .card-body::before {
  content: '';
  background: $red-socgen;
  width: 1rem;
  height: 1rem;
  display: block;
  margin-bottom: 1rem;
}

//
// HYPER CARD
// Mix of hyperlink and a card
//

.card[href] {
  color: inherit;

  &:hover {
    text-decoration: none;
  }
}

//
// Optional textual caps
//

.card-header {
  // Less border more space
  // you can still have a border  by using `.border-bottom`
  border-bottom: none;
}

.card-footer {
  // Less border more space
  // you can still have a border  by using `.border-top`
  border-top: none;
}

/*
 * No adapted colors inside `.card`
 */

[class*='bg-lvl'] .card,
[class*='bg-alt-lvl'] .card {
  @each $name, $color in map-get($adaptive-palette, 'bg-lvl1') {
    // Buttons
    .btn.btn-#{$name} {
      color: $primary-alt;
      background-color: $color;
      border: none;

      @include button-in-card-states($color);
    }

    // Outline Buttons
    .btn.btn-outline-#{$name} {
      color: $color;
      border-color: $color;

      @include button-in-card-states($color);
    }

    // Discreet Buttons
    .btn.btn-discreet-#{$name} {
      color: $color;

      @include button-in-card-states($color);
    }

    // Flat Buttons
    .btn.btn-discreet-#{$name} {
      color: $color;

      @include button-in-card-states($color);
    }

    // Badges
    .badge.badge-#{$name} {
      color: $primary-alt;
      background-color: $color;
    }

    // Outline badges
    .badge.badge-outline-#{$name} {
      color: $color;
      border-color: $color;
    }

    // Discreet badges
    .badge.badge-discreet-#{$name} {
      color: $color;
    }

    // Texts
    .text-#{$name} {
      color: $color !important;
    }
  }
}

//
// INTERNAL FLAG
//

.sgcib-only,
.sgcib-only-mini {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 1rem 1rem 0;
  border-color: transparent $red-socgen transparent transparent;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 20;
}

.sgcib-only-mini {
  border-width: 0 0.5rem 0.5rem 0;
}

// CARD-CHECKBOX
@mixin card-checkbox-interaction($color) {
  &:hover {
    background-color: get-interaction-color('hover', $color) !important;
    outline: 1px solid $color;
  }

  &:active {
    background-color: get-interaction-color('onclick', $color) !important;
  }

  &:focus-visible {
    background-color: get-interaction-color('hover', $color) !important;
  }

  &:has(input:focus-visible) {
    background-color: get-interaction-color('hover', $color) !important;
    outline: 2px solid $color;
  }

  &:has(input:checked) {
    background-color: get-interaction-color('selected', $color);
    outline: 2px solid $color;
    &:hover {
      background-color: get-interaction-color('selected_hover', $color) !important;
    }
    &:active {
      background-color: get-interaction-color('onclick', $color) !important;
    }

    &:focus-visible {
      background-color: get-interaction-color('hover', $color) !important;
    }
  }
}

.card-checkbox {
  @extend .card;
  @extend .card-bordered;

  .form-check-label:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    cursor: pointer;
  }

  @include card-checkbox-interaction($primary);

  @each $state, $color in $states {
    &:has(.form-check-#{$state}) {
      @include card-checkbox-interaction($color);
    }
  }

  .form-check {
    margin-right: $check-height-md;
    margin-bottom: 0;
  }

  .form-check-sm {
    margin-right: $check-height-sm;
  }

  .form-check-lg {
    margin-right: $check-height-lg;
  }

  .form-check-xl {
    margin-right: $check-height-xl;
  }

  .form-check-input {
    left: 0 !important;
  }
}
