.alert-discreet-success, .alert-outline-success {
  --#{$prefix}alert-color: #{light-discreet-color('bg-lvl1', 'success')} !important;
  .alert-link {
    color: #{light-discreet-color('bg-lvl1', 'success')} !important;
  }
}
.alert-discreet-danger, .alert-outline-danger {
  --#{$prefix}alert-color: #{light-discreet-color('bg-lvl1', 'danger')} !important;
  .alert-link {
    color: #{light-discreet-color('bg-lvl1', 'danger')} !important;
  }
}
.alert-discreet-secondary {
  --#{$prefix}alert-color: #{light-discreet-color('bg-lvl1', 'secondary')} !important;
  .alert-link {
    color: #{light-discreet-color('bg-lvl1', 'secondary')} !important;
  }
}

.alert-discreet-info {
  --#{$prefix}alert-color: #{light-discreet-color('bg-lvl1', 'info')} !important;
  .alert-link {
    color: #{light-discreet-color('bg-lvl1', 'info')} !important;
  }
}

.alert-discreet-warning, .alert-outline-warning {
  --#{$prefix}alert-color: #{light-discreet-color('bg-lvl1', 'warning')} !important;
  .alert-link {
    color: #{light-discreet-color('bg-lvl1', 'warning')} !important;
  }
}